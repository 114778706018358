import {
  createLocalizedPathnamesNavigation,
  Pathnames,
} from "next-intl/navigation";

/**
 * This is the list of all locales that we support linking to, and their translations.
 * https://next-intl-docs.vercel.app/docs/routing/navigation#localized-pathnames
 */

export const locales = ["en", "de"] as const;

export const pathnames = {
  "/": {
    en: "/",
    de: "/",
  },
  "/contact": {
    en: "/contact",
    de: "/kontakt",
  },
  "/cookies": {
    en: "/cookies",
    de: "/cookies",
  },
  "/find-a-therapist": {
    en: "/find-a-therapist",
    de: "/finde-einen-therapeuten",
  },
  "/find-a-therapist/online-therapy": {
    en: "/find-a-therapist/online-therapy",
    de: "/finde-einen-therapeuten/online-therapie",
  },
  "/find-a-therapist/speaking/[lang]": {
    en: "/find-a-therapist/speaking/[lang]",
    de: "/finde-einen-therapeuten/sprechende/[lang]",
  },
  "/find-a-therapist/[city_slug]": {
    en: "/find-a-therapist/[city_slug]",
    de: "/finde-einen-therapeuten/[city_slug]",
  },
  "/find-a-therapist/berlin/mitte": {
    en: "/find-a-therapist/berlin/mitte",
    de: "/finde-einen-therapeuten/berlin/mitte",
  },
  "/find-a-therapist/berlin/neukolln": {
    en: "/find-a-therapist/berlin/neukolln",
    de: "/finde-einen-therapeuten/berlin/neukolln",
  },
  "/find-a-therapist/berlin/kreuzberg": {
    en: "/find-a-therapist/berlin/kreuzberg",
    de: "/finde-einen-therapeuten/berlin/kreuzberg",
  },
  "/find-a-therapist/berlin/friedrichshain": {
    en: "/find-a-therapist/berlin/friedrichshain",
    de: "/finde-einen-therapeuten/berlin/friedrichshain",
  },
  "/find-a-therapist/berlin/pankow": {
    en: "/find-a-therapist/berlin/pankow",
    de: "/finde-einen-therapeuten/berlin/pankow",
  },
  "/find-a-therapist/[city_slug]/[profile_slug]": {
    en: "/find-a-therapist/[city_slug]/[profile_slug]",
    de: "/finde-einen-therapeuten/[city_slug]/[profile_slug]",
  },
  "/find-help-with/[category_slug]": {
    en: "/find-help-with/[category_slug]",
    de: "/finde-hilfe/[category_slug]",
  },
  "/for-companies": {
    en: "/for-companies",
    de: "/for-companies", // framer does nto translate slugs
  },
  "/for-companies/employee-assistance": {
    en: "/for-companies/employee-assistance",
    de: "/fuer-unternehmen/employee-assistance",
  },
  "/for-therapists": {
    en: "/for-therapists",
    de: "/for-therapists", // framer does nto translate slugs
  },
  "/imprint": {
    en: "/imprint",
    de: "/impressum",
  },
  "/our-story": {
    en: "/our-story",
    de: "/unsere-geschichte",
  },
  "/press": {
    en: "/page/press",
    de: "/page/presse",
  },
  "/privacy": {
    en: "/privacy",
    de: "/datenschutz",
  },
  "/security": {
    en: "/security",
    de: "/sicherheit",
  },
  "/sitemap": {
    en: "/sitemap",
    de: "/sitemap",
  },
  "/approaches": {
    en: "/approaches",
    de: "/ansatz",
  },
  "/specializations": {
    en: "/specializations",
    de: "/spezialgebiete",
  },
  "/cities": {
    en: "/cities",
    de: "/staedte",
  },
  "/languages": {
    en: "/languages",
    de: "/sprachen",
  },
  "/support/faq": {
    en: "/support/faq",
    de: "/support/faq",
  },
  "/support/faq/[parent_category_slug]/[category_slug]/[faq_slug]": {
    en: "/support/faq/[parent_category_slug]/[category_slug]/[faq_slug]",
    de: "/support/faq/[parent_category_slug]/[category_slug]/[faq_slug]",
  },
  "/terms": {
    en: "/terms",
    de: "/agb",
  },
  "/profile/[uri]": {
    // I have no idea what this is for, I just copied it from our old Routes??
    en: "find-a-therapist{uri}",
    de: "finde-einen-therapeuten{uri}",
  },
  "/apply": {
    en: "/apply",
    de: "/apply",
  },
  "/matching/survey": {
    en: "/matching/survey",
    de: "/matching/survey",
  },
  "/find-help-with/online-therapy": {
    en: "/find-help-with/online-therapy",
    de: "/finde-hilfe/online-therapie",
  },
  "/eap": {
    en: "/eap",
    de: "/eap",
  },
  "/self-tests/adhd": {
    en: "/self-tests/adhd",
    de: "/selbsttests/adhs",
  },
  "/self-tests/adhd/test": {
    en: "/self-tests/adhd/test",
    de: "/selbsttests/adhs/test",
  },
  "/self-tests/adhd/results": {
    en: "/self-tests/adhd/results",
    de: "/selbsttests/adhs/ergebnisse",
  },
  "/self-tests/depression": {
    en: "/self-tests/depression",
    de: "/selbsttests/depression",
  },
  "/self-tests/depression/test": {
    en: "/self-tests/depression/test",
    de: "/selbsttests/depression/test",
  },
  "/self-tests/depression/results": {
    en: "/self-tests/depression/results",
    de: "/selbsttests/depression/ergebnisse",
  },
  "/home/profile/create": {
    en: "/home/profile/create",
    de: "/home/profile/create",
  },
  "/home/profile/create/practice-info": {
    en: "/home/profile/create/practice-info",
    de: "/home/profile/create/practice-info",
  },
  "/home/profile/create/expertise-areas": {
    en: "/home/profile/create/expertise-areas",
    de: "/home/profile/create/expertise-areas",
  },
  "/home/profile/create/about-and-approach": {
    en: "/home/profile/create/about-and-approach",
    de: "/home/profile/create/about-and-approach",
  },
  "/home/profile/create/photos": {
    en: "/home/profile/create/photos",
    de: "/home/profile/create/photos",
  },
  "/home/profile/create/experience": {
    en: "/home/profile/create/experience",
    de: "/home/profile/create/experience",
  },
  "/home/profile/create/diplomas": {
    en: "/home/profile/create/diplomas",
    de: "/home/profile/create/diplomas",
  },
} satisfies Pathnames<typeof locales>;

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createLocalizedPathnamesNavigation({ locales, pathnames });
